import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './vinculacion.scss';

var baseUrl = process.env.REACT_APP_SERVICES_URL_CRM;

const renderTutorial = () => {
  return (
    <li>
      <Link
        to={
          'https://www.exponencialconfirming.com/affiCRM/vinculacionesCRM?id=1'
        }
        target='_blank'
      >
        Consultar y realizar operaciones
      </Link>
      <br />
    </li>
  );
};

function Vinculacion(props) {
  return (
    <Row justify='center'>
      <Col justify='center'>
        <Form.Item name='submit'>
          <Button
            className='btn btn-green'
            style={{ width: '300px' }}
            //onClick={() => this.formRef.current.submit()}
          >
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`${baseUrl}?id=${props.scPersona}&token=${props.authToken}`}
            >
              Abrir Vinculación en una nueva pestaña
            </a>
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
    scPersona: state.auth.scPersona,
  };
};

export default connect(mapStateToProps)(Vinculacion);
